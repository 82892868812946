// ============================================================================
// ChatService
// -----------
// Chat module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/ChatApi'

// -------
// Exports
// -------
export default {
	decrementChatNotifications: function (num = 0) {
		const total = store.state.chat.chatNotifications
		if (total > 0) {
			store.dispatch('chat/setChatNotifications', total - num)
		}
	},
	getChannel: function (accountingFirmId, vendorId = null) {
		let result
		if (vendorId) {
			result = API.getCompanyChannel(accountingFirmId, vendorId).then(res => res.data.data)
		} else {
			result = API.getAccountingFirmChannel(accountingFirmId).then(res => res.data.data)
		}
		return result
	},
	getClientChannels: function (accountingFirmId) {
		return API.getClientChannels(accountingFirmId).then(res => res.data.data)
	},
	getNotifications: function (accountingFirmId) {
		return API.getNotifications(accountingFirmId, {}, { show_error: false })
			.then(res => res.data.data)
			.then(result => {
				let notifications = 0
				const keys = Object.keys(result)
				keys.forEach(key => {
					if (result[key] > 0) {
						notifications = notifications + result[key]
					}
				})
				store.dispatch('chat/setChatNotifications', notifications)
				return result
			})
			.catch()
	},
	getToken: function () {
		const accountingFirmId = store.state.accountingFirm?.selected?.id
		return API.getToken(accountingFirmId)
			.then(res => res.data.data)
			.then(data => data.access_token)
	},
	logoutUser: function (accountingFirmId, token) {
		if (token) {
			return API.logoutUser(accountingFirmId, { rc_token: token }).catch(() => {})
		}
	}
}
