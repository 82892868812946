<template>
	<v-layout v-if="clients" column fill-height>
		<v-flex v-show="!mini" shrink px-3>
			<v-text-field v-model="clientsSearch" hide-details single-line clearable :label="$t('actions.search')" prepend-inner-icon="search"></v-text-field>
		</v-flex>
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<v-flex grow style="height: 0">
			<v-list :dense="mini">
				<v-subheader v-t="'chat.internal'" />
				<v-list-tile :value="channelSelected === null" @click="selectChannel()">
					<v-list-tile-avatar v-if="mini">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-badge color="red" overlap>
									<template v-slot:badge>
										<span v-if="accountingFirmNotification > 0">{{ accountingFirmNotification }}</span>
									</template>
									<v-avatar size="2.5em" class="pointer" color="primary" v-on="on">
										<span class="white--text" v-text="accountingFirm.abbreviation" />
									</v-avatar>
								</v-badge>
							</template>
							<span v-text="accountingFirm.name" />
						</v-tooltip>
					</v-list-tile-avatar>
					<v-list-tile-avatar v-else>
						<v-avatar size="2.5em" class="pointer" color="primary">
							<span class="white--text" v-text="accountingFirm.abbreviation" />
						</v-avatar>
					</v-list-tile-avatar>
					<v-list-tile-content v-show="!mini">
						<v-list-tile-title class="text-truncate" v-text="accountingFirm.name" />
					</v-list-tile-content>
				</v-list-tile>
			</v-list>
			<v-subheader v-t="'chat.clients'" />
			<w-virtual-list :items="clients" :mini="mini">
				<template v-slot:default="{ item: client }">
					<v-list-tile :value="client.id === channelSelected" avatar @click="selectChannel(client)">
						<v-list-tile-avatar v-if="mini">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-avatar size="2.5em" class="pointer" color="primary" v-on="on">
										<span class="white--text" v-text="client.abbreviation" />
									</v-avatar>
								</template>
								<span v-text="client.name" />
							</v-tooltip>
						</v-list-tile-avatar>
						<v-list-tile-avatar v-else>
							<v-badge color="red" overlap :value="client.notifications > 0">
								<template v-slot:badge>
									<span>{{ client.notifications }}</span>
								</template>
								<v-avatar size="2.5em" class="pointer" color="primary">
									<v-flex white--text>{{ client.abbreviation }}</v-flex>
								</v-avatar>
							</v-badge>
						</v-list-tile-avatar>
						<v-list-tile-content v-show="!mini">
							<v-list-tile-title class="text-truncate" v-text="client.name" />
						</v-list-tile-content>
					</v-list-tile>
				</template>
			</w-virtual-list>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import ChatModuleGuard from '@/mixins/ModulesGuards/Chat/ChatModuleGuard'

export default {
	name: 'ChannelList',
	mixins: [ChatModuleGuard],
	data: function () {
		return {
			accountingFirmNotification: 0,
			clients: [],
			clientsSearch: '',
			mini: false
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected,
			company: state => state.company.selected
		}),
		clientList: function () {
			return this.clients.filter(client => client.company.toLowerCase().indexOf(this.clientsSearch.trim().toLowerCase()) !== -1)
		},
		channelSelected: function () {
			if (this.$parent?.vendorId) {
				return this.$parent.vendorId
			}
			return null
		}
	},
	watch: {
		mini: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		},
		loading: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		}
	},
	created: function () {
		this.getClientChannels()
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized }
			]
		},
		getClientChannels: function () {
			this.loading = true
			this.service
				.getClientChannels(this.accountingFirm.id)
				.then(clients => {
					clients.sort(this.appService.compareValues('company', 'asc'))
					this.clients = clients.map(client => {
						if (client.client_code) {
							client.name = client.company + ' (' + client.client_code + ')'
						} else if (client.pivot && client.pivot.client_code) {
							client.name = client.company + ' (' + client.pivot.client_code + ')'
						} else {
							client.name = client.company
						}
						return client
					})
					this.getNotifications()
				})
				.finally(() => {
					this.loading = false
				})
		},
		getNotifications: function () {
			this.service.getNotifications(this.accountingFirmId).then(res => {
				const channelSelected = this.channelSelected
				const keys = Object.keys(res)
				keys.forEach(item => {
					if (item === 'AF-' + this.accountingFirmId && channelSelected === null) {
						this.accountingFirmNotification = res[item]
						if (this.channelSelected === null && res[item] > 0) {
							this.service.decrementChatNotifications(res[item])
						}
					} else {
						const clientId = parseInt(item.replace('AFC-' + this.accountingFirmId + '-', ''))
						const foundClient = this.clients.find(client => client.id === clientId)
						if (foundClient && foundClient.id !== channelSelected) {
							foundClient.notifications = res[item]
						}
					}
				})
				this.$forceUpdate()
			})
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		},
		selectChannel: function (company = null) {
			this.appService.goTo({
				name: 'chat',
				params: {
					vendor_id: company?.id
				}
			})
		}
	}
}
</script>
