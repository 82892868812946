import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/chat/')

const getAccountingFirmChannel = accountingFirmId => {
	const url = `${accountingFirmId}/channels/`
	return axios.get(url)
}

const getCompanyChannel = (accountingFirmId, vendorId) => {
	const url = `${accountingFirmId}/channels/${vendorId}`
	return axios.get(url)
}

const getClientChannels = accountingFirmId => {
	const url = `${accountingFirmId}/channels/clients`
	return axios.get(url)
}

const getNotifications = (accountingFirmId, params, config) => {
	const url = `${accountingFirmId}/notifications`
	return axios.get(url, {
		...config,
		params: params
	})
}

const getToken = accountingFirmId => {
	const url = `${accountingFirmId}/token`
	return axios.get(url)
}

const logoutUser = (accountingFirmId, data) => {
	const url = `${accountingFirmId}/logout`
	return axios.post(url, data).catch(() => {})
}

export default {
	getAccountingFirmChannel: getAccountingFirmChannel,
	getClientChannels: getClientChannels,
	getCompanyChannel: getCompanyChannel,
	getNotifications: getNotifications,
	getToken: getToken,
	logoutUser: logoutUser
}
