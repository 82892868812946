<template>
	<w-layout fill-height full-width>
		<ChatContent :context="context" />
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";

import ChannelList from '@/components/Chat/ChannelList'
import Vue from 'vue'

export default {
	name: 'ChatModule',
	components: {
		ChatContent: () => ({
			component: import('@/components/Chat/ChatContent')
		})
	},
	mixins: [
		CustomersManagerModuleGuard,
		HandleSideBarMixin,
	],
	data: function () {
		return {
			src: null
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		})
	},
	created: function () {
		this.init()
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setHasSidebar(false)
		this.setPermanentSidebar(false)
	},
	methods: {
		init: function () {
			if (this.isAccountant) {
				this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
				const ChannelListClass = Vue.extend(ChannelList)
				const channelListComponent = new ChannelListClass({
					parent: this
				})
				this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
					canMinimize: true,
					moduleInstance: channelListComponent,
					title: this.$t('chat.channels_list'),
					emitter: this
				})
				this.setHasSidebar(true)
				this.setPermanentSidebar(true)
			}
		}
	}
}
</script>
