import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/Chat/ChatService'
import { Events, Bus } from '@/events/Chat/ChatEvents'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: ['chat', 'chat-client'],
			service: Service,
			eventBus: Bus,
			events: Events
		}
	}
}
